import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Риббаза Турійськ
			</title>
			<meta name={"description"} content={"Там, де кожен закид має значення - приєднуйтесь до братерства рибалок у Риббаза Турійськ"} />
			<meta property={"og:title"} content={"Головна | Риббаза Турійськ"} />
			<meta property={"og:description"} content={"Там, де кожен закид має значення - приєднуйтесь до братерства рибалок у Риббаза Турійськ"} />
			<meta property={"og:image"} content={"https://megalluxo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://megalluxo.com/img/4374589241.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://megalluxo.com/img/4374589241.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://megalluxo.com/img/4374589241.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://megalluxo.com/img/4374589241.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://megalluxo.com/img/4374589241.png"} />
			<meta name={"msapplication-TileImage"} content={"https://megalluxo.com/img/4374589241.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				Приєднуйтесь до братерства рибалок
			</Text>
			<Text
				margin="0px 0px 30px 0px"
				font="normal 600 64px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 20px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Риббаза Турійськ
			</Text>
			<Text margin="0px 0px 70px 0px" text-align="center" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
			Ласкаво просимо до рибальського клубу Риббаза Турійськ, ідеального місця для тих, хто прагне не просто порибалити, а зануритися в справжній дух риболовлі. Наш клуб - це поєднання традицій, дружби та прагнення до найкращих вражень від риболовлі.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://megalluxo.com/img/1.jpg"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
						Ексклюзивні місця для риболовлі
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
					Доступ до найпопулярніших місць для риболовлі, де води кишать різноманітною рибою.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://megalluxo.com/img/2.jpg"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
						Спільнота рибалок
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
					Приєднуйтесь до групи однодумців, які поділяють вашу пристрасть до риболовлі. Обмінюйтеся історіями, порадами та заводьте міцну дружбу.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://megalluxo.com/img/3.jpg"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
						Поради експертів
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
					Незалежно від того, чи є ви досвідченим рибалкою, чи тільки починаєте, наші досвідчені члени та гіди запропонують вам безцінну інформацію та поради.
					</Text>
				</Box>
			</Box>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Завітайте до нас
			</Button>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
			Унікальні переваги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Регулярні змагання та заходи
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Беріть участь в організованих клубом рибальських змаганнях, громадських заходах та освітніх семінарах.
					</Text>
				</Box>
				<Image src="https://megalluxo.com/img/4.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://megalluxo.com/img/5.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Підтримка з обладнанням та спорядженням
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Скористайтеся клубними знижками на першокласне рибальське спорядження та порадами щодо найкращого обладнання для ваших риболовних пригод.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});